<template>
  <div class="home">
    <BgTop></BgTop>
    <div class="home-main">
      <!-- 通知模块 -->
      <div class="notice-gold">
        <div class="notice-box">
          <img class="notice" src="@/assets/image/notice.png" />
          <span>xx成功兑换100元xx成功兑换100元xx成功兑换100元</span>
        </div>
        <div class="gold-box" @click="goUrl('/mobile/signIn')">
          <span>签到</span>
          <img class="gold-coin" src="@/assets/image/gold-coin.png" />
        </div>
      </div>
      <!-- 轮播 -->
      <Lunbox></Lunbox>
      <!-- 问卷模块 -->
      <div class="questionnaire-box">
        <div class="questionnaire-title-box">
          <div
            :class="[
              'typeList-item',
              { 'typeList-item-active': typeActive === item }
            ]"
            v-for="item in typeList"
            :key="item"
            @click="changeTypeActive(item)"
          >
            {{ item }}
            <span v-if="typeActive === item"></span>
          </div>
        </div>

        <div class="questionnaire-contet">
          <div
            class="questionnaire-item"
            v-for="(item, index) in questionList"
            :key="index"
          >
            <div class="questionnaire-item-title">
              <div class="name-left">
                <div class="number">{{ item.number }}</div>
                <div class="name">{{ item.title }}</div>
              </div>
              <img class="icon-discuss" src="@/assets/image/icon-discuss.png" />
            </div>
            <div class="questionnaire-star-box">
              <div class="star-box">
                <div v-for="CItem in 5" :key="CItem">
                  <img
                    v-if="isTrue(CItem, 1, item.ir)"
                    src="@/assets/image/icon-star1.png"
                  />
                  <img
                    v-if="isTrue(CItem, 2, item.ir)"
                    src="@/assets/image/icon-star2.png"
                  />
                  <img
                    v-if="isTrue(CItem, 3, item.ir)"
                    src="@/assets/image/icon-star3.png"
                  />
                </div>
              </div>
              <div class="grade-box">
                <img
                  class="icon-grade"
                  v-if="Increase"
                  :src="require(`@/assets/image/credit${IncreaseNumber}.png`)"
                />
                <span class="grade-number" v-if="Increase"
                  >{{ Increase }}%</span
                >
                <img
                  class="arrow-top"
                  v-if="Increase"
                  src="@/assets/image/arrow-top.png"
                />
                <span class="gold-number">{{ item.maxPoints }}</span>
                <span class="name">金币</span>
              </div>
            </div>
            <div class="line"></div>
            <div class="questionnaire-bottom">
              <div class="bottom-left">
                <img class="icon-clock" src="@/assets/image/icon-clock.png" />
                <span>{{ item.loi }}分钟</span>
              </div>
              <div class="bottom-right">
                <span v-if="item.allowedHide" @click="HiddenSurvey(item, index)"
                  >忽略</span
                >
                <div class="btn" @click="takeIn(item)">开始答题</div>
              </div>
            </div>
          </div>

          <van-empty v-if="questionList.length === 0" description="暂无数据" />
        </div>
      </div>

      <Tabbar :tabbarType="0"></Tabbar>
    </div>
  </div>
</template>

<script>
import Lunbox from "@/components/lunbox.vue";
export default {
  data() {
    return {
      typeList: ["推荐问卷", "话题讨论", "幸运问卷"],
      typeActive: "推荐问卷",
      questionList: [],
      credit: 0,
      Increase: 0,
      IncreaseNumber: 1
    };
  },
  methods: {
    goUrl(path) {
      this.$router.push(path);
    },
    isTrue(index, value, data) {
      let isTrue = false;
      if (value === 1) {
        const number = parseInt(data / 20);
        if (index <= number) {
          isTrue = true;
        }
      }
      if (value === 2) {
        const number = parseInt(data / 20);

        const remainder = data % 20;
        if (remainder >= 10) {
          if (index > number + 1) {
            isTrue = true;
          }
        } else {
          if (index > number) {
            isTrue = true;
          }
        }
      }
      if (value === 3) {
        const number = parseInt(data / 20);

        const remainder = data % 20;
        if (remainder >= 10) {
          if (index === number + 1) {
            isTrue = true;
          }
        }
      }
      return isTrue;
    },
    async changeTypeActive(data) {
      this.typeActive = data;
      this.questionList = [];
      let userid = localStorage.getItem("userid") || "";

      if (this.typeActive === "推荐问卷") {
        let res1 = await this.$http.get(
          `/api/v1/Accounts/${userid}/IndividualSurveys`,
          {
            params: {
              id: userid
            }
          }
        );
        console.log("res1", res1);
        if (res1.status === 200) {
          this.questionList = res1.data;
        }
      }

      if (this.typeActive === "话题讨论") {
        //
        console.log("测试");
      }
      if (this.typeActive === "幸运问卷") {
        let res2 = await this.$http.get(
          `/api/v1/Accounts/${userid}/SurveysOfLucid`,
          {
            params: {
              id: userid
            }
          }
        );
        if (res2.status === 200) {
          this.questionList = this.questionList.concat(res2.data);
        }

        let res3 = await this.$http.get(
          `/api/v1/Accounts/${userid}/SurveysOfSSI`,
          {
            params: {
              id: userid
            }
          }
        );
        if (res3.status === 200) {
          this.questionList = this.questionList.concat(res3.data);
        }

        let res4 = await this.$http.get(
          `/api/v1/Accounts/${userid}/SurveysOfGMO`,
          {
            params: {
              id: userid
            }
          }
        );
        if (res4.status === 200) {
          this.questionList = this.questionList.concat(res4.data);
        }

        let res5 = await this.$http.get(
          `/api/v1/Accounts/${userid}/SurveysOfCint`,
          {
            params: {
              id: userid
            }
          }
        );
        if (res5.status === 200) {
          this.questionList = this.questionList.concat(res5.data);
        }

        let res6 = await this.$http.get(
          `/api/v1/Accounts/${userid}/SurveysOfYours`,
          {
            params: {
              id: userid
            }
          }
        );
        if (res6.status === 200) {
          this.questionList = this.questionList.concat(res6.data);
        }
      }
    },
    //忽略
    HiddenSurvey(value, index) {
      let userid = localStorage.getItem("userid") || "";
      this.$dialog
        .confirm({
          title: "提示",
          message:
            "此调查，如果提示您参加过或者您不愿意参加，您可以忽略本项目。忽略后，您将无法再参与本项目，请确认是否忽略？"
        })
        .then(() => {
          // on confirm
          this.$http
            .post(`/api/v1/Accounts/${userid}/HiddenSurvey`, null, {
              params: {
                surveyID: value.encryptId,
                surveySeed: value.surveySeed,
                id: userid
              }
            })
            .then(res => {
              if (res.status === 200) {
                console.log("res", res);
                this.$toast("忽略成功");
                this.questionList.splice(index, 1);
              }
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    takeIn(t) {
      if (this.typeActive === "幸运问卷") {
        return window.open(t.surveyLink);
      }
      return this.$router.push(
        `/mobile/survey/${t.encryptId}?add=${this.Increase}&grade=${this.IncreaseNumber}`
      );
    }
  },
  components: {
    Lunbox
  },
  async created() {
    let userid = localStorage.getItem("userid") || "";
    if (!userid) {
      this.$router.push("/mobile");
    }
    const res = await this.$http.get(`/api/v1/Accounts/${userid}`, {
      params: {
        id: userid
      }
    });

    if (res.status === 200) {
      console.log("res", res.data);
      localStorage.setItem("userInfo", JSON.stringify(res.data));
      this.credit = res.data.credit;

      if (this.credit <= 60) {
        this.Increase = 0;
        this.IncreaseNumber = 1;
      } else if (61 <= this.credit && this.credit <= 100) {
        this.Increase = 5;
        this.IncreaseNumber = 2;
      } else if (101 <= this.credit && this.credit <= 200) {
        this.Increase = 10;
        this.IncreaseNumber = 3;
      } else if (201 <= this.credit && this.credit <= 500) {
        this.Increase = 15;
        this.IncreaseNumber = 4;
      } else if (501 <= this.credit && this.credit <= 800) {
        this.Increase = 20;
        this.IncreaseNumber = 5;
      } else {
        this.Increase = 40;
        this.IncreaseNumber = 6;
      }
    }
    this.changeTypeActive("推荐问卷");
  }
};
</script>

<style lang="scss" scoped>
.home {
  min-height: 100vh;
  width: 100%;
  position: relative;
  background-color: #f5f5f8;

  .home-main {
    min-height: 100vh;
    width: 100%;
    position: relative;
    z-index: 2;
    box-sizing: border-box;
    padding: 10px 15px 60px 15px;
    .notice-gold {
      display: flex;
      align-items: center;
      .notice-box {
        width: 100%;
        height: 34px;
        background: #ffffff;
        border-radius: 17px;
        color: #232323;
        font-size: 14px;
        display: flex;
        align-items: center;

        overflow: hidden;

        .notice {
          width: 18px;
          margin: 0 11px 0 15px;
          flex-shrink: 0;
        }
        span {
          width: calc(100% - 44px);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .gold-box {
        flex-shrink: 0;
        margin-left: 9px;
        display: flex;
        align-items: center;
        color: #232323;
        font-size: 14px;
        .gold-coin {
          width: 23px;
          margin-left: 5px;
        }
      }
    }
    .questionnaire-box {
      margin-top: 10px;
      .questionnaire-title-box {
        display: flex;
        align-items: center;
        height: 40px;
        .typeList-item {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 28px;
          cursor: pointer;
          span {
            display: block;
            width: 14px;
            height: 4px;
            background: #ffa200;
            border-radius: 10px 10px 10px 10px;
            margin-top: 7px;
          }
        }
        .typeList-item-active {
          color: #ffa200;
          font-size: 22px;
          font-weight: bolder;
        }
      }
      .questionnaire-contet {
        margin-top: 10px;
        width: 100%;
        // background-color: #ffa200;
        height: calc(100vh - 174px - 34vw);
        min-height: 300px;
        overflow: auto;
        .questionnaire-item {
          width: 100%;
          background: #ffffff;
          border-radius: 8px 8px 8px 8px;
          padding: 10px 10px 0 10px;
          box-sizing: border-box;
          margin-bottom: 10px;
          .questionnaire-item-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 5px;
            .icon-discuss {
              width: 18px;
              flex-shrink: 0;
              margin-left: 10px;
            }
            .name-left {
              width: 100%;
              display: flex;
              color: #232323;
              font-size: 16px;
              font-weight: bolder;
              overflow: hidden;
              .number {
                flex-shrink: 0;
                margin-right: 9px;
              }
              .name {
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
          .questionnaire-star-box {
            margin-top: 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .star-box {
              display: flex;
              img {
                width: 16px;
                margin-right: 4px;
              }
            }
            .grade-box {
              display: flex;
              align-items: flex-end;
              .icon-grade {
                width: 20px;
                margin-right: 6px;
              }
              .grade-number {
                color: #ffa200;
                font-size: 16px;
                font-weight: bolder;
              }
              .arrow-top {
                width: 12px;
                margin-left: 5px;
                margin-right: 20px;
              }
              .gold-number {
                font-size: 20px;
                color: #ff6017;
                font-weight: bolder;
                margin-right: 6px;
                position: relative;
                top: 4px;
              }
              .name {
                font-size: 10px;
                color: #232323;
              }
            }
          }
          .line {
            background-color: #ededed;
            width: 100%;
            height: 1px;
            margin-top: 9px;
          }
          .questionnaire-bottom {
            display: flex;
            height: 46px;
            justify-content: space-between;
            .bottom-left {
              display: flex;
              align-items: center;
              color: #999999;
              font-size: 12px;
              .icon-clock {
                width: 14px;
                margin-right: 3px;
              }
            }
            .bottom-right {
              display: flex;
              align-items: center;
              color: #666666;
              font-size: 14px;
              .btn {
                width: 80px;
                height: 28px;
                background: linear-gradient(320deg, #ff7a4a 0%, #ff9c21 100%);
                border-radius: 14px 14px 14px 14px;
                text-align: center;
                line-height: 28px;
                color: #fff;
                font-size: 14px;
                letter-spacing: 2px;
                margin-left: 34px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
