<template>
  <div class="sample-lunbo">
    <div class="sample-lunbo2">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img
            src="@/assets/image/lunbo1.png"
            @click="goUrl('/mobile/myCredit')"
          />
        </div>
        <div class="swiper-slide">
          <img
            src="@/assets/image/lunbo2.png"
            @click="goUrl('/mobile/myCredit')"
          />
        </div>
        <div class="swiper-slide">
          <img
            src="@/assets/image/lunbo3.png"
            @click="goUrl('/mobile/turntable')"
          />
        </div>
        <div class="swiper-slide">
          <img
            src="@/assets/image/lunbo4.png"
            @click="goUrl('/mobile/red-envelope')"
          />
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    goUrl(path) {
      this.$router.push(path);
    }
  },
  mounted() {
    new window.Swiper(".sample-lunbo2", {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 30,
      autoplay: {
        pauseOnMouseEnter: true,
        delay: 2000,
        disableOnInteraction: false
      },
      pagination: {
        el: ".swiper-pagination"
      }
    });
  }
};
</script>
<style lang="scss" scoped>
.sample-lunbo2 {
  overflow: hidden;
  width: 100%;
  margin-top: 10px;
  position: relative;
  height: auto;
  .swiper-wrapper {
    position: relative;
    width: 100%;
    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        display: block;
      }
    }
  }
  .swiper-pagination {
    position: absolute;
    // bottom: 12px;
  }
}
</style>
